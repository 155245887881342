import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { MdEmail, MdPhone } from 'react-icons/md';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagramSquare } from 'react-icons/fa';

const ContactCard = () => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <MdEmail />
          </Grid>
          <Grid item>
            <Typography variant="body1">capametasesores@gmail.com</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <MdPhone />
          </Grid>
          <Grid item>
            <Typography variant="body1">+52 55 3697 3027</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <FaFacebookSquare aria-label="Facebook.com" color="#3b5998" size={50} onClick={() => window.open('https://www.facebook.com/capamet')}/>
          <FaInstagramSquare aria-label="Instagram.com" color="linear-gradient(135deg, #405de6, #e4405f)" size={50} onClick={() => window.open('https://www.instagram.com/capametcursos/')}/>
    </Card>
  );
};

export default ContactCard;