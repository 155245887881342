import certECSI from "../../assets/img/certificaciones/CERT-ECSI.jpg"
import certNAEMT from "../../assets/img/certificaciones/CERT-NAEMT.jpg"
import certPC from "../../assets/img/certificaciones/CERT-PC.jpg"
//import certADIEL from "../../assets/img/certificaciones/CERT-ADIEL.jpg"
import certSTPS from "../../assets/img/certificaciones/CERT-STPS.png"
import CONOCER from "../../assets/img/certificaciones/redconocer.jpeg"
import ICEM from "../../assets/img/certificaciones/icem.jpeg"


export const certificaciones = [
    {
        "asc": "CONOCER",
        "imagen": CONOCER
    },
    {
        "asc": "ICEM",
        "imagen": ICEM
    },
    {
        "asc": "ECSI",
        "imagen": certECSI
    },
    {
        "asc": "NAEMT",
        "imagen": certNAEMT
    },
    {
        "asc": "PC",
        "imagen": certPC
    },
    {
        "asc": "STPS",
        "imagen": certSTPS
    }
];