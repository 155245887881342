import React from "react"
import { Box } from "@mui/material";
import {Typography} from "@mui/material";
import { fondoClaro, colorSecundario } from "../UI/variables";
import mapa from "../../assets/img/mapa.png"
import {useMediaQuery} from "@mui/material";


export default function Maps(){


    return (
      <div style={{ width: '80%', height: '450px', position: 'relative', margin:"auto", marginBottom:"20px",padding:"10px" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46815.11200074529!2d-99.15624070412728!3d19.338368105902653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f9f56fd846f7%3A0xb73d0f115e3945e5!2sCAPAMET!5e0!3m2!1ses-419!2smx!4v1720742877749!5m2!1ses-419!2smx"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Mapa de Google"
        ></iframe>
      </div>
    );
  };